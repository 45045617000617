.support {
  background: radial-gradient(
      100% 100% at 50% 100%,
      rgb(37, 68, 50, 1) 0%,
      rgba(37, 68, 50, 0.1) 100%
    )
    var(--dark-bg, #061917);
  overflow: hidden;
  padding: var(--margin-section) 0;
  position: relative;
  color: var(--pure-white);
  h2 {
    color: var(--pure-white);
    text-align: center;
    margin-bottom: 64px;
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &-items {
    display: flex;
    gap: 48px;
    text-align: center;
    flex-wrap: wrap;
    margin-bottom: 40px;
  }
  &-item {
    min-width: 320px;
    flex: 1;
    .title {
      color: var(--pure-white);
      margin: 20px 0 12px;
    }
    .description {
      opacity: 0.8;
    }
  }
}
