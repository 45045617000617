footer {
  padding-top: 80px;
  background: var(--pure-white);
  .footer-contacts {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .footer-navigation {
    display: flex;
    gap: 40px;
    .logo {
      height: 32px;
    }
    .link {
      line-height: 28px;
    }
    @media (max-width: 640px) {
      flex-direction: column;
    }
  }

  .footer-menu-list {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    gap: 40px;
  }

  nav {
    flex: 1 0 26%;
    min-width: 160px;
  }

  .footer-menu {
    display: flex;
    flex-direction: column;
    gap: 12px;
    a {
      display: flex;
      align-items: center;
      gap: 8px;
      font-family: var(--font-body);
      color: var(--text);
      text-decoration: none;
      &:hover {
        color: var(--accent);
        text-decoration: underline;
      }
    }
  }

  .meta {
    background-color: var(--dark-bg);
    padding: 20px 0;
    margin-top: 60px;
    border-top: 1px solid var(--divider);
    .container {
      display: flex;
      justify-content: space-between;
      color: var(--pure-white);
      @media (max-width: 640px) {
        flex-direction: column;
        align-items: center;
        gap: 16px;
      }
    }
    ul {
      display: flex;
      gap: 32px;
      a {
        color: var(--pure-white);
        &:hover {
          color: var(--accent-hover);
        }
      }
    }
  }

  @media (max-width: 480px) {
    nav {
      min-width: 120px;
    }
    .social {
      justify-content: start;
    }
  }
}
