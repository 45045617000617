input[type="email"],
input[type="password"],
input[type="phone"],
input[type="text"] {
  font-family: var(--font-body);
  border: 2px solid #7c7c7c;
  border-radius: 4px;
  caret-color: #4aa199;
  padding: 16px;
  transition: all 0.2s ease-in-out;
  outline: none;
  &:focus {
    border-color: var(--accent);
  }
}

.input {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.input-button {
  display: flex;
  gap: 12px;
  align-items: flex-end;
  max-width: 100%;
  .input {
    flex-grow: 1;
  }
  @media (max-width: 640px) {
    flex-direction: column;
    align-items: stretch;
  }
}
