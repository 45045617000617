.faq {
  display: flex;
  gap: 64px;
  margin: var(--margin-section) auto;

  h2 {
    flex: 0 0 50%;
  }
  &-container {
    display: flex;
    gap: 80px;
    justify-content: center;
    padding: 0 20px;
    margin-top: 40px;
    .link {
      font-family: var(--font-body);
      color: var(--title);
      font-size: 18px;
      background: none;
      border: none;
      text-align: left;
      &.active {
        color: var(--accent);
      }
    }
    @media (max-width: 1024px) {
      gap: 24px;
    }
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  &-navigation {
    align-self: flex-start;
    position: sticky;
    top: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    min-width: 320px;
    max-width: 320px;
    padding: 32px;
    background-color: #f9f9f9;
    border: 1px solid var(--divider);
    list-style: none;
    border-radius: 16px;
    @media (max-width: 768px) {
      position: static;
      max-width: 100%;
      width: 100%;
    }
  }

  &-categories {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  &-item {
    margin-bottom: 12px;
    border: 2px solid var(--divider);
    border-radius: 8px;
    background: #fff;
    overflow: hidden;
    flex: 1 0 50%;

    .title {
      display: flex;
      justify-content: space-between;
      gap: 8px;
      padding: 24px;
      margin-bottom: 0;
      cursor: pointer;

      &:hover {
        color: var(--accent-hover);
      }

      &::after {
        content: "";
        position: relative;
        top: 8px;
        min-width: 12px;
        height: 12px;
        border-bottom: 2px solid var(--title);
        border-right: 2px solid var(--title);
        transform: rotate(45deg);
        transition: all 0.35s;
      }
      @media (max-width: 768px) {
        padding: 16px;
        font-size: 18px;
        line-height: 24px;
      }
    }

    .faq-answer {
      max-height: 0;
      overflow: hidden;
      padding-left: 12px;
      margin: 0 24px;
      border-left: 2px solid var(--accent);
      transition: all 0.35s ease;
      @media (max-width: 768px) {
        margin: 0 16px;
      }
    }

    &.open {
      .title {
        color: var(--accent);
        cursor: default;

        &::after {
          border-color: var(--accent);
          transform: rotate(45deg) scale(-1);
        }
      }

      .faq-answer {
        max-height: 600px;
        margin: 24px;
        margin-top: -12px;
        @media (max-width: 768px) {
          margin: 0 16px 16px;
        }
      }
    }
  }
}
