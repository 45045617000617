.pricing {
  &-page {
    margin-top: var(--margin-header);
  }
  &-head {
    text-align: center;
    .custom-list {
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      gap: 12px;
      margin-top: 0;
      li {
        align-items: center;
        padding: 8px 24px 8px 12px;
        border-radius: 24px;
        border: 1px solid var(--divider);
        background-color: #fff;
      }
    }
  }
}

.plans {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 24px;
  margin-top: var(--margin-header);
  &-compare {
    margin-top: var(--margin-header);
    @media (max-width: 767px) {
      display: none;
    }
  }
  &-header {
    display: flex;
    align-items: center;
    gap: 12px;
    position: sticky;
    top: 0;
    background: #fff;
    border: 1px solid var(--divider);
    border-radius: 8px;
    box-shadow: 0px 15px 30px 0px rgba(71, 103, 136, 0.06),
      0px 8px 15px 0px rgba(71, 103, 136, 0.03),
      0px 4px 4px 0px rgba(71, 103, 136, 0.04);
    z-index: 5;
    .col {
      padding: 24px 16px;
      border-left: 1px solid var(--divider);
      flex: 1;
      &:first-of-type {
        border: none;
      }
      h3 {
        margin-bottom: 0;
      }
    }
  }
  &-features {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  &-category {
    &-title {
      display: block;
      border-radius: 4px;
      background: var(--title, #061b23);
      color: #fff;
      text-align: center;
      padding: 16px;
      font-family: var(--font-body);
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: 4px;
    }
  }
}

.plan {
  flex: 1;
  border-radius: 12px;
  border: 2px solid var(--divider, #d8e0df);
  background: #fff;
  padding: 24px 0;
  .cta {
    min-width: 240px;
  }
  &-head {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Roboto Slab";
    text-align: center;
    gap: 12px;
    padding: 24px 40px;
  }
  &-name {
    color: vat(--title);
    font-family: var(--font-body);
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
  }
  &-price {
    color: var(--accent, #36827b);
    font-size: 40px;
    font-weight: 600;
    line-height: 48px;
  }
  &-mainfeature {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    padding: 12px 24px;
  }
  &-enterprise {
    display: flex;
    gap: 32px;
    align-items: center;
    margin-top: 24px;
    padding: 24px;
    background: var(--pure-white);
    border-radius: 12px;
    border: 2px solid var(--divider, #d8e0df);
    &-info {
      display: flex;
      flex-direction: column;
      gap: 12px;
      .title {
        font-size: 32px;
        line-height: 40px;
      }
    }
    @media (max-width: 767px) {
      flex-direction: column;
    }
  }
  &-additional {
    width: 72px;
    padding: 4px;
    border-radius: 4px;
    border: 2px solid #cbe3df;
    background: #fff;
    color: var(--accent-active, #125e57);
    font-size: 13px;
    font-weight: bold;
    text-align: center;
    line-height: 16px;
    text-wrap: nowrap;
    &-explanation {
      position: absolute;
      top: 0;
      right: 76px;
      font-size: 12px;
      background: #fff;
      color: var(--title);
      font-weight: bold;
      border: 1px solid var(--divider);
      text-wrap: nowrap;
      border-radius: 4px;
      padding: 0 8px;
      opacity: 0;
      z-index: -1;
    }
  }
  &-value {
    position: relative;
    display: flex;
    gap: 8px;
    align-items: center;
    font-family: var(--font-title);
    font-weight: bold;
    &:hover {
      .plan-additional-explanation {
        opacity: 1;
        z-index: 1;
      }
    }
  }
  &-feature {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  &:nth-child(2) {
    position: relative;
    border-color: var(--accent);
    box-shadow: 0px 12px 48px 0px rgba(18, 94, 87, 0.16),
      0px 8px 15px 0px rgba(18, 94, 87, 0.03),
      0px 4px 4px 0px rgba(18, 94, 87, 0.04);
    &::before {
      content: "Popular Plan";
      position: absolute;
      top: -10px;
      left: -2px;
      display: block;
      text-align: center;
      width: calc(100% + 4px);
      padding: 4px 56px;
      background: linear-gradient(115deg, #4583bc, #4d69af);
      text-wrap: nowrap;
      color: #fff;
      font-weight: bold;
      font-family: var(--font-body);
      line-height: 40px;
      border-radius: 4px;
    }
  }
  &-features-head {
    text-align: center;
    p {
      font-size: 24px;
      margin-bottom: 12px;
      font-weight: bold;
    }
    span {
      color: var(--accent-active);
      font-weight: normal;
    }
  }
  .more-features {
    display: none;
    margin: 24px auto 0;
    background-color: #333;
  }
  @media (max-width: 767px) {
    padding-top: 0;
    &-head {
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      padding: 16px 0;
      border-radius: 12px 12px 0 0;
      border-bottom: 1px solid var(--divider);
    }
    &-price {
      font-size: 32px;
      color: var(--title);
      &::before {
        content: " - ";
      }
    }
    &-description {
      display: none;
    }
    &:nth-child(2) {
      padding-top: 32px;
      &:before {
        z-index: 20;
      }
    }
    .more-features {
      display: flex;
    }
  }
}

.category-table {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 12px;
  .row {
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid var(--divider);
    border-radius: 4px;
    color: var(--title, #061b23);
    font-family: var(--font-body);
    font-size: 18px;
    background-color: #fff;
    &:hover {
      transform: scale(1.01);
      box-shadow: var(--soft-shadow);
      z-index: 2;
    }
    .col {
      display: flex;
      flex: 1;
      align-items: center;
      padding: 12px 12px;
      &:not(.feature-name) {
        text-align: center;
      }
      &.feature-name {
        display: flex;
        gap: 8px;
        align-items: center;
        .more-feature-info {
          position: relative;
          img {
            opacity: 0.6;
            min-width: 24px;
          }
          p {
            position: absolute;
            top: 50%;
            left: calc(100% + 8px);
            padding: 12px;
            width: 0;
            font-size: 14px;
            line-height: 20px;
            background-color: #fff;
            border: 1px solid var(--divider);
            border-radius: 4px;
            transform: translateY(-50%);
            opacity: 0;
            z-index: -2;
            transition: all 0s;
          }
        }
        &:hover {
          img {
            opacity: 1;
          }
          p {
            width: 320px;
            opacity: 1;
            z-index: 0;
            transition: opacity 0.35s;
          }
        }
      }
    }
    &:nth-child(odd) {
      background: var(--light-background, #eef5f4);
    }
  }
}

.pricing-faq {
  @media (max-width: 768px) {
    .faq {
      flex-direction: column;
      gap: 8px;
    }
  }
}

.modal {
  width: 100%;
  &-features {
    overflow: auto;
    max-height: 100dvh;
  }
  &-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999999999;
  }
  &-content {
    background-color: #fff;
    width: 100%;
    padding-bottom: 40px;
    .feature-category {
      margin-top: 20px;
      padding: 0 16px;
    }
    .category-table .row {
      font-size: 16px;
      .col.feature-name {
        flex: 1 0 80%;
      }
    }
    .plans-header {
      top: 48px;
    }
  }
  &-close-button {
    position: fixed;
    display: flex;
    align-items: center;
    gap: 16px;
    top: 0;
    left: 0;
    border: none;
    line-height: 48px;
    font-family: var(--font-body);
    font-weight: bold;
    width: 100%;
    border-bottom: 1px solid var(--divider);
    padding: 0 16px;
    &::before {
      content: "";
      display: block;
      width: 12px;
      height: 12px;
      border-top: 2px solid #333;
      border-right: 2px solid #333;
      rotate: -135deg;
    }
    &::after {
      content: "";
      position: absolute;
      left: 16px;
      top: 23px;
      height: 2px;
      width: 16px;
      background-color: #333;
    }
  }
  &-controls {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #333;
    display: flex;
    justify-content: space-between;
    gap: 12px;
    button {
      flex: 1;
      border: none;
      text-align: center;
      color: #fff;
      font-family: var(--font-body);
      font-weight: bold;
      line-height: 48px;
      background: none;
    }
  }
  &-prev-button,
  &-next-button {
    display: flex;
    align-items: center;
    padding: 0 16px;

    gap: 8px;

    &::before {
      content: "";
      display: block;
      width: 12px;
      height: 12px;
      border-top: 2px solid #fff;
      border-right: 2px solid #fff;
    }
  }
  &-prev-button {
    &::before {
      rotate: -135deg;
    }
  }
  &-next-button {
    flex-direction: row-reverse;
    &::before {
      rotate: 45deg;
    }
  }
}
