.statistic {
  &-container {
    position: relative;
    padding: 0 20px var(--margin-section);
    background: radial-gradient(
        60% 90% at 50% 100%,
        rgb(37, 68, 50) 0%,
        rgba(37, 68, 50, 0.1) 100%
      )
      #061917;
    color: #fff;
    margin-top: 400px;
    @media (max-width: 1024px) {
      margin-top: 200px;
      .video-wrapper {
        margin-bottom: -40px;
      }
    }
  }

  &-wrapper {
    position: relative;
    display: flex;
    gap: 120px;
    justify-content: center;
    align-items: stretch;
    max-width: 920px;
    width: 100%;
    margin: auto;
    z-index: 1;

    .metric {
      &-item {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        color: #fff;
        max-width: 320px;
        text-align: center;
        min-width: 180px;
        padding: 40px 0;
        &:not(:last-of-type):after {
          content: "";
          position: absolute;
          top: 0;
          right: -60px;
          height: 100%;
          width: 2px;
          background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0.02) 0%,
            rgba(255, 255, 255, 0.3) 50%,
            rgba(255, 255, 255, 0.02) 100%
          );
          @media (max-width: 980px) {
            display: none;
          }
        }
        @media (max-width: 980px) {
          padding: 0;
          gap: 8px;
          flex: 1 0 30%;
          max-width: initial;
        }
      }

      &-value {
        color: var(--pure-white, #fff);
        font-family: var(--font-title);
        font-size: 88px;
        line-height: 80px;
        @media (max-width: 980px) {
          font-size: 48px;
          line-height: 56px;
        }
      }

      &-description {
        color: var(--light-background, #eef5f4);
        font-family: var(--font-body);
        font-size: 18px;
        line-height: 32px;
        opacity: 0.8;
        @media (max-width: 980px) {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
    @media (max-width: 980px) {
      gap: 24px;
      flex-wrap: wrap;
    }
  }
}
