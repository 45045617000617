.sitemap {
  h1 {
    text-align: left;
    flex: 1 0 100%;
  }
  .container {
    display: flex;
    gap: 48px 24px;
    flex-wrap: wrap;
    section {
      flex: 1 0 40%;
      a {
        color: var(--text);
        &:hover {
          color: var(--accent);
          text-decoration: underline;
        }
      }
    }
  }
}
