.checkbox {
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  input {
    display: none;
  }
  label {
    cursor: pointer;
    position: relative;
    font-family: var(--font-body);
    padding-left: 32px;
    line-height: 24px;
    &::before {
      position: absolute;
      left: 0;
      top: 0;
      background: var(--pure-white, #fff);
      border: 1px solid var(--grayscale, #848484);
      border-radius: 4px;
      content: "";
      display: block;
      height: 24px;
      width: 24px;
      transition: all 0.35s;
    }
    &::after {
      border-bottom: 2px solid rgba(132, 132, 132, 0);
      border-right: 2px solid rgba(132, 132, 132, 0);
      content: "";
      display: block;
      height: 14px;
      left: 8px;
      position: absolute;
      rotate: 45deg;
      top: 2px;
      transition: all 0.35s;
      width: 8px;
    }
    &:hover:after {
      border-color: #848484;
    }
  }

  input:checked {
    & + label {
      &:before {
        background: var(--accent, #36827b);
        border-color: var(--accent, #36827b);
      }
      &:after {
        border-color: var(--pure-white);
      }
    }
  }
}
