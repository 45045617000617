.demo {
  background-color: #fff;
  padding: var(--margin-section) 20px;
  border-top: 1px solid var(--accent);
  .container {
    display: flex;
    gap: 88px;
    padding: 0;
    @media (max-width: "1400px") {
      gap: 32px;
    }
    @media (max-width: "1320px") {
      flex-direction: column-reverse;
      gap: 32px;
    }
    .target-integration {
      align-self: center;
    }
  }
  &-head {
    .cta-container {
      justify-content: center;
    }
  }
}

.calendly-embed {
  min-width: 320px;
  flex: 1 0 660px;
  min-height: 780px;
  border-radius: 16px;
  overflow: hidden;
  border: 1px solid var(--divider);

  .title {
    position: relative;
    margin: 60px auto -40px;
    text-align: center;
    font-weight: bold;
    z-index: 4;
    @media (max-width: 640px) {
      margin-bottom: 20px;
      font-size: 18px;
      line-height: 24px;
      padding: 0 20px;
    }
  }
}
