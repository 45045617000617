// Note on generic classes
// Some commonly named classes are used across without a unique name, but nested
// in the cascade to avoid conflicts: For example '.meta' is used as a generic name
// but styled differently as a child of .price and as a child of .mainFooter.
// Recommendation is to avoid adding a global .meta class and update the separate
// ones.

@import "./reset.scss";
@import "./variables.scss";
@import "./typography.scss";
@import "./extend.scss";
@import "./base.scss";

//!layouts
@import "./layouts/header.scss";
@import "./layouts/footer.scss";
@import "./layouts/team.scss";
@import "./layouts/statistics.scss";
@import "./layouts/steps.scss";
@import "./layouts/academy.scss";
@import "./layouts/testimonials.scss";
@import "./layouts/hardware.scss";
@import "./layouts/landing-inventory.scss";
@import "./layouts/integration.scss";
@import "./layouts/support.scss";
@import "./layouts/singleintegration.scss";
@import "./layouts/features.scss";
@import "./layouts/pricing.scss";
@import "./layouts/demo.scss";
@import "./layouts/aside.scss";
@import "./layouts/free-tools.scss";
@import "./layouts/sitemap.scss";
@import "./layouts/ai.scss";
@import "./layouts/industries.scss";
@import "./layouts/signup.scss";
@import "./layouts/video.scss";
@import "./layouts/homepage.scss";
@import "./layouts/logocarousel.scss";
@import "./layouts/faq.scss";
@import "./layouts/contact.scss";
@import "./layouts/case-study.scss";

//!common
@import "./common/cta.scss";
@import "./common/checkbox.scss";
@import "./common/input.scss";
@import "./common/custom-selector.scss";

.no-scroll {
  overflow: hidden;
}

.link {
  color: var(--title);
  &:hover {
    color: var(--accent);
    text-decoration: underline;
  }
}

.phone-number {
  color: var(--title);
}

.accent {
  color: #3f8b84;
}

.text-page {
  margin-top: var(--margin-header);
  padding: 0 20px;
  h1 {
    margin-bottom: 32px;
    text-align: left;
  }
  a {
    color: var(--accent);
    &:hover {
      text-decoration: underline;
    }
  }
  p {
    margin-bottom: 24px;
    color: var(--text);
  }

  ul {
    list-style: initial;
  }

  ol {
    list-style: decimal;
  }

  ol,
  ul {
    margin-left: 16px;
    ul,
    ol {
      margin-top: 16px;
    }
    li {
      margin-bottom: 16px;
    }
  }
  @media (max-width: 768px) {
    margin-top: var(--margin-header);
  }
}

.description {
  font-size: 18px;
}

.divider-or {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  font-weight: bold;
  margin: 40px 0;
  text-transform: uppercase;
  hr {
    border: 1px solid #aaa;
    flex-grow: 1;
    max-width: 240px;
  }
}

.changelogItem {
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid var(--divider);
  time {
    display: flex;
    gap: 12px;
    font-size: 24px;
    line-height: 32px;
    font-weight: bold;
    margin-bottom: 24px;
    color: var(--accent);
  }
  ul {
    margin-left: 40px;
  }
}

.health411 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  .icon {
    width: 80px;
  }
}

.redirecting {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--pure-white);
  opacity: 0.85;
  z-index: 10;
}

.lds-facebook,
.lds-facebook div {
  box-sizing: border-box;
}
.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: var(--accent);
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0s;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}
.youtubePlayer {
  aspect-ratio: 16 / 9;
  border-radius: 16px;
  margin-bottom: 32px;
}

.success,
.error {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: inline-block;
  margin-top: 32px;
  padding: 24px;
  background-color: var(--light-bg);
  border: 1px solid var(--accent);
  border-radius: 12px;
  margin: 24px 0;
}

.error {
  background: #fdfbfa;
  border: 1px solid var(--error);
}

.page-head {
  text-align: center;
  margin: var(--margin-header) auto;
  h1 {
    margin-bottom: 12px;
  }
  p {
    max-width: 720px;
    margin: -0 auto 24px;
  }
  .calendary-head {
    position: relative;
    margin: 32px auto -48px;
  }
  @media (max-width: 640px) {
    p {
      margin: 0 auto;
    }
  }
}

.email-form {
  display: flex;
  width: 100%;
  max-width: 640px;
  margin: 32px auto;
  border: 2px solid var(--accent);
  border-radius: 8px;
  overflow: hidden;

  .email-input {
    flex-grow: 1;
    border: none;
    outline: none;
  }

  @media (max-width: 480px) {
    flex-direction: column;
    border-radius: 4px;
    border: none;
    .email-input {
      border: 2px solid var(--text);
      border-radius: 4px;
      margin-bottom: 16px;
    }
    button {
      border-radius: 4px;
    }
  }
  @media (max-width: 1023px) {
    max-width: inherit;
  }
}

.live-demo {
  display: flex;
  gap: 24px;
  align-items: center;
  margin: 80px 0;
  hr {
    flex-grow: 1;
    @media (max-width: 768px) {
      display: none;
    }
  }
}

.video-player {
  position: absolute;
  top: 0;
  left: 0;
}

.notfound {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  justify-content: center;
  height: 50dvh;
  margin-top: -64px;
  text-align: center;
}

.no-contract {
  background-color: #fff;
  border: 2px solid var(--divider);
  border-radius: 20px;
  color: var(--title);
  font-family: var(--font-title);
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
  padding: 12px 24px;
  width: -webkit-max-content;
  width: max-content;
}

.triger-section {
  margin-top: 40px;
  background: linear-gradient(
    252deg,
    #edfbde 0%,
    #e3f9e3 14%,
    #d6f5e7 28%,
    #d0f2e8 42%,
    #cceee9 56%,
    #ccebe9 70%,
    #cce7e9 84.77%,
    #cce3e7 100%
  );
  border-radius: 16px;
  padding: 32px;
  h3 {
    margin-top: 0 !important;
  }
  .email-form {
    margin: 40px 0 0;
  }

  .cta-container {
    margin-top: 20px;
  }

  @media (max-width: 640px) {
    padding: 20px;
  }
}

.middle-text {
  text-align: center;
  max-width: 860px;
  margin: -16px auto 0;
  padding: 0 20px;
  text-wrap: balance;
}
