.hero-container {
  position: relative;
  .video-bg {
    opacity: 0.2;
  }
}

.hero-inventory {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  text-align: center;
  margin: -64px auto -360px;
  text-align: center;
  max-width: 70rem;
  padding: 80px 20px 400px;
  h1 {
    margin-bottom: 0;
  }

  @media (max-width: 1024px) {
    margin-top: -40px;
  }

  @media (max-width: 640px) {
    gap: 1rem;
  }
}

.invenory-features {
  margin: var(--margin-section) auto;
  h2 {
    margin-top: 16px;
  }
  &-list {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    margin-top: 40px;
  }
  &-item {
    flex: 1 0 40%;
    padding: 40px;
    background-color: var(--pure-white);
    border-radius: 24px;
    border: 1px solid var(--divider);
    min-width: 320px;
    img {
      margin-bottom: 16px;
    }
  }
  &-title {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 12px;
  }
  @media (max-width: 960px) {
    &-list {
      gap: 12px;
      margin-top: 24px;
    }
    &-item {
      padding: 24px;
      border-radius: 12px;
      text-align: center;
    }
    &-title {
      flex-direction: column;
      font-size: 18px;
    }
  }
}

.tags {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.5rem;

  @media (max-width: 768px) {
    gap: 0.5rem;
  }
}

.tag {
  display: flex;
  align-items: center;
  font-family: var(--font-body);
  padding: 1rem 1.5rem;
  max-width: max-content;
  justify-content: center;
  gap: 0.5rem;
  border-radius: 4rem;
  border: 1px solid var(--divider, #d8e0df);
  background: var(--pure-white, #fff);
  letter-spacing: 0.02rem;
  @media (max-width: 768px) {
    padding: 0.5rem 0.75rem;
    gap: 0.25rem;
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 0.03rem;
  }
}

.inventory-pricing {
  gap: 32px;
  padding: var(--margin-section) 0;
  background: radial-gradient(
      40% 80% at 20% 0%,
      rgb(37, 68, 50, 0.5) 0%,
      rgba(37, 68, 50, 0.1) 100%
    ),
    radial-gradient(
        60% 80% at 90% 100%,
        rgb(37, 68, 50, 0.5) 0%,
        rgba(37, 68, 50, 0.1) 100%
      )
      var(--dark-bg);
  color: var(--pure-white);

  .pricing {
    &-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: auto;
      @extend .container;
    }

    &-list {
      display: flex;
      gap: 16px;
      justify-content: space-around;
      flex-wrap: wrap;
      width: 100%;
      margin-top: 32px;
    }

    &-card {
      position: relative;
      display: flex;
      padding: 48px 32px;
      flex-direction: column;
      justify-content: center;
      align-items: stretch;
      gap: 2.5rem;
      flex: 1 0 0;
      border-radius: 12px;
      overflow: hidden;
      min-width: 320px;
      background: #0a2926;
      &.best {
        background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.08) 0%,
            rgba(255, 255, 255, 0.08) 100%
          ),
          radial-gradient(
              60% 70% at 80% -5%,
              rgb(37, 68, 50) 0%,
              rgba(37, 68, 50, 0.1) 100%
            )
            #0a2926;
      }
      &-price {
        color: var(--pure-white, #fff);
        font-family: var(--font-title);
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 40px;
        margin-bottom: 0;
      }
      &-name {
        color: var(--pure-white, #fff);
        font-family: var(--font-title);
        font-size: 2rem;
        font-style: normal;
        font-weight: 600;
        line-height: 2.25rem;
      }
      &-include {
        li {
          display: flex;
          height: 3.5rem;
          padding: 1rem 1.5rem;
          align-items: flex-start;
          gap: 0.75rem;
          align-self: stretch;
          &:not(:first-of-type) {
            border-top: 1px solid rgba(216, 224, 223, 0.2);
          }
        }
      }
    }
  }

  h2 {
    color: var(--pure-white, #fff);
    max-width: 52rem;
    text-align: center;
  }

  .go-to-pro {
    width: 100%;
    padding: 24px 32px;
    margin-top: 40px;
    border-radius: 12px;
    border: 2px solid rgba(216, 224, 223, 0.12);
    background: rgba(6, 25, 23, 0.6);
    backdrop-filter: blur(6px);
    p {
      text-align: center;
      max-width: 780px;
      margin: auto;
    }
    a {
      color: var(--accent, #36827b);
      text-decoration-line: underline;
      &:hover {
        color: var(--accent-hover);
      }
    }
  }
}
