.steps {
  background: linear-gradient(
    252deg,
    #edfbde 0%,
    #e3f9e3 15%,
    #d6f5e7 25%,
    #d0f2e8 40%,
    #cceee9 55%,
    #ccebe9 70%,
    #cce7e9 85%,
    #cce3e7 100%
  );
  padding: var(--margin-section) 0;
  margin-bottom: var(--margin-section);
  &-description {
    max-width: 1000px;
  }
  &-items {
    display: flex;
    gap: 24px;
    margin: 32px 0;
    @media (max-width: 1200px) {
      flex-wrap: wrap;
    }
  }
  &-item {
    min-width: 260px;
    flex: 1 0 0;
    padding: 32px 24px;
    border-radius: 12px;
    background: var(--pure-white);
    box-shadow: 0px 15px 30px 0px rgba(38, 109, 103, 0.06),
      0px 8px 15px 0px rgba(38, 109, 103, 0.03),
      0px 4px 4px 0px rgba(38, 109, 103, 0.04);
    .title {
      margin: 8px 0 12px;
    }
    &:last-of-type {
      .step-arrow {
        display: none;
      }
    }
    ul {
      display: flex;
      flex-direction: column;
      gap: 12px;
      li {
        color: rgba(62, 61, 64, 0.8);
        list-style-type: disc;
        margin-left: 16px;
      }
    }
  }
  .ctas {
    justify-content: center;
  }
  .step {
    &-number {
      display: flex;
      align-items: center;
      gap: 12px;
      color: var(--accent);
      font-size: 48px;
      font-weight: 600;
      line-height: 1;
    }
    &-arrow {
      position: relative;
      display: flex;
      align-items: center;
      flex: 1;
      height: 2px;
      background-color: var(--accent);
      &::after {
        position: absolute;
        right: 0;
        top: -5px;
        display: block;
        content: "";
        width: 12px;
        height: 12px;
        border-right: 2px solid var(--accent);
        border-top: 2px solid var(--accent);
        transform: rotate(45deg);
      }
    }
  }
}
