:root {
  // Colors
  --main-background: #f9fbfa;
  --light-bg: #eef5f4;
  --dark-bg: #061917;
  --brand-color: #4aa199;
  --pure-white: #ffffff;
  --title: #061b23;
  --text: #3e3e3e;
  --divider: #d8e0df;
  --grayscale: #848484;
  --error: #f25f5c;
  --warning: #ffe066;
  --info: #667eff;
  --info-bg: #e0f3fe;
  --accent: #36827b;
  --accent-hover: #3f8b84;
  --accent-active: #125e57;
  --accent-light-hover: #dffaed;
  --accent-light-active: #c0f5e2;
  --soft-shadow: 0px 24px 24px 0px rgba(6, 25, 23, 0.08);
  --arround-shadow: 0px 0 48px 12px rgba(6, 25, 23, 0.04);

  // gap
  --gap-small: 12px;
  --gap-medium: 24px;
  --gap-large: 120px;
  --border-radius: 16px;
  --menu-padding: 20px;
  --margin-section: 120px;
  --margin-header: 64px;
  --margin-size-l: 80px;

  // animation time
  --submenu-transition: 0.35s;

  @media (max-width: "1024px") {
    --margin-section: 80px;
    --margin-header: 40px;
    --margin-size-l: 48px;
  }

  @media (max-width: "760px") {
    --margin-section: 40px;
    --margin-header: 24px;
  }
}

.dark-theme {
  --main-background: #23272d;
  --dark-bg: #061917;
  --brand-color: #4aa199;
  --pure-white: #35444f;
  --title: #fff;
  --text: rgba(255, 255, 255, 0.8);
  --divider: #d8e0df;
  --info-bg: #e0f3fe;
  p {
    color: var(--text);
  }
}
