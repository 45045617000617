.ai {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  h1 {
    margin-bottom: 0;
    color: var(--title);
  }
  h2 {
    color: var(--title);
  }
  h3 {
    color: var(--title);
  }
  p {
    margin-bottom: 16px;
  }
  &-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    margin-top: var(--margin-header);
  }
  &-section-divider {
    display: flex;
    gap: 24px;
    align-items: center;
    justify-content: center;
    margin: var(--margin-header);
    width: 100%;
    hr {
      flex-grow: 1;
      border-color: rgba(255, 255, 255, 0.2);
      max-width: 240px;
    }
  }
  &-cta {
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 8px;
    padding: 24px 48px;
    background: radial-gradient(
      263.06% 3990.4% at 181.76% -32.46%,
      #403855 0,
      #5fb7ab 100%
    );
    color: #fff;
    font-size: 18px;
    span {
      opacity: 0.8;
    }
  }

  &-videos {
    display: flex;
    gap: 80px;
    margin: 48px 0 0;
    padding: 0 20px;
    @media (max-width: 1280px) {
      gap: 40px;
    }
    @media (max-width: 1024px) {
      flex-direction: column;
    }
  }

  .tag {
    color: var(--text);
    margin: 24px auto;
  }
  &-upcoming {
    &-items {
      display: flex;
      flex-wrap: wrap;
      gap: 80px;
      padding: 40px 20px;
      text-align: left;
      @media (max-width: 980px) {
        flex-direction: column;
      }
    }
    &-item {
      flex: 0 0 calc(50% - 40px);
      min-width: 320px;
      img {
        margin-bottom: 24px;
      }
    }
  }
}
