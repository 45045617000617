.landing {
  &-hero {
    background: linear-gradient(
      252deg,
      #edfbde 0%,
      #e3f9e3 15%,
      #d6f5e7 25%,
      #d0f2e8 40%,
      #cceee9 55%,
      #ccebe9 70%,
      #cce7e9 85%,
      #cce3e7 100%
    );
    display: flex;
    justify-content: center;
    gap: 24px;
  }

  &-info {
    padding: 64px 0;
    flex: 0 0 calc(1308px / 2);
    h1 {
      font-size: 44px;
      line-height: 48px;
      margin-bottom: 16px;
    }
  }
  &-integrations {
    background: var(--pure-white);
    padding: var(--margin-section) 0;
    &-content {
      display: flex;
      gap: 20px;
      align-items: center;
      margin-bottom: 40px;
      h2 {
        flex: 1 0 58%;
        margin-bottom: 0;
      }
      p {
        margin-bottom: 20px;
      }
    }
    .live-demo {
      margin-bottom: 0;
    }
    &-slider {
      overflow-x: hidden;
    }
    @media (max-width: 1024px) {
      &-content {
        flex-direction: column;
        gap: 16px;
      }
    }
  }

  &-video {
    position: relative;
    flex: 0 0 calc(1308px / 2);
    .video-wrapper {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 480px;
      width: calc(100% + 120px);
      .preview {
        height: 100%;
      }
    }
  }

  @media (max-width: 1600px) {
    &-info {
      flex: 0 0 480px;
    }
  }

  @media (max-width: 1400px) {
    &-hero {
      flex-direction: column;
      align-items: center;
    }
    &-info {
      max-width: 800px;
      flex: auto;
      margin: 0 20px;
      padding-bottom: 0;
    }
    &-video {
      flex: auto;
      bottom: -40px;
      .video-wrapper {
        position: relative;
        height: auto;
        width: calc(100% - 200px);
        aspect-ratio: 16 / 9;
        margin: auto;
      }
    }
  }
  @media (max-width: 960px) {
    &-hero {
      padding: var(--margin-section) 0;
      flex-direction: column-reverse;
    }

    &-info {
      padding: 0;
    }
    &-video {
      bottom: 0;
      .video-wrapper {
        width: calc(100% - 40px);
      }
    }
  }
}

.pro {
  &-features {
    margin-top: var(--margin-section);
    &-items {
      display: flex;
      flex-direction: column;
      gap: var(--margin-size-l) 40px;
    }
    h2 {
      margin-bottom: var(--margin-header);
    }
  }
  &-feature {
    display: flex;
    gap: 24px;
    align-items: center;
    justify-content: space-between;
    &-image {
      flex: 0 0 auto;
    }
    .description {
      margin-bottom: 16px;
    }
    h3 {
      color: var(--title);
      margin-bottom: 16px;
      &:hover {
        color: var(--accent);
      }
    }
    &:nth-child(even) {
      flex-direction: row-reverse;
    }
    &-content {
      max-width: 640px;
    }
  }
  @media (max-width: 1200px) {
    &-features {
      &-items {
        align-items: stretch;
        flex-direction: row;
        flex-wrap: wrap;
      }
    }
    &-feature {
      flex-direction: column !important;
      flex: 1 0 40%;
      &-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        // justify-content: space-between;
        .description {
          flex: 1;
        }
      }
    }
  }
  @media (max-width: 960px) {
    &-features {
      h2 {
        text-align: center;
      }
    }
    &-feature {
      min-width: 100%;
    }
  }
}
