.container {
  max-width: 1360px;
  margin: auto;
  padding: 0 20px;
  &-short {
    max-width: 960px;
    padding: 0 20px;
  }
}

.cta-container {
  display: flex;
  gap: 16px;
  @media (max-width: 640px) {
    flex-direction: column;
  }
}
