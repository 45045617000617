.features {
  padding: var(--margin-header) 0 var(--margin-section);
  background-color: var(--pure-white);
  margin-bottom: var(--margin-section);
  &-content {
    display: flex;
    gap: 40px;
  }
  &-menu-list {
    position: sticky;
    top: 20px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    .aside-menu {
      position: static;
    }
    .title {
      font-weight: bold;
    }
  }
  &-list {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .video-wrapper {
      transform: initial;
      margin: initial;
    }
  }
  .feature {
    &-item {
      max-width: 768px;
      img {
        margin-bottom: 20px;
      }
    }
    &-info {
      max-width: 768px;
      .cta {
        margin-top: 20px;
        max-width: max-content;
      }
    }
  }

  .triger-section {
    max-width: 768px;
    margin-top: 0;
  }

  @media (max-width: 767px) {
    padding-top: var(--margin-header);
    margin-top: 0;
    &-content {
      flex-direction: column-reverse;
    }
    &-menu-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 12px;
      .features-menu {
        flex: 1 0 auto;
      }
    }
  }
}
