.free-tools {
  &-head {
    padding: 64px 20px;
    text-align: center;
    max-width: 1240px;
    margin: auto;
    p {
      margin-bottom: 16px;
    }
  }
  &-body {
    background-color: var(--pure-white);
    border-top: 1px solid var(--accent);
    padding: var(--margin-header) 20px;
    h2 {
      text-align: center;
    }
    .container {
      display: flex;
      gap: 20px;
      background: var(--main-background);
      border-radius: 24px;
      border: 1px solid var(--divider);
      padding: 0;
    }
    .live-preview {
      flex: 1 0 70%;
      padding: 40px;
    }
    .add-form {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 40px;
      color: var(--pure-white);
      background-color: #303232;
      border-radius: 16px 0 0 16px;
      flex: 1 0 320px;
      max-width: 480px;
      input {
        width: 100%;
      }
    }
    .cta {
      border: none;
      width: 100%;
    }
    @media (max-width: 1279px) {
      padding: 0;
      .container {
        flex-direction: column;
      }
      .add-form {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
        flex: 1 0 auto;
        border-radius: 0;
        .input {
          flex: 1 0 30%;
        }
      }
      .live-preview {
        padding: 32px 20px;
      }
      @media (max-width: 1279px) {
        .add-form {
          padding: 40px 20px;
          flex-wrap: wrap;
          max-width: inherit;
          .input {
            min-width: 320px;
          }
        }
      }
    }
    &.shipping-label {
      @media (max-width: 1279px) {
        .add-form {
          padding: 40px 20px;
          flex-wrap: wrap;
          max-width: inherit;
          .input {
            min-width: initial;
            flex: 1 0 0;
          }
        }
      }
    }
    .preview {
      display: flex;
      flex-direction: column;
      padding: 24px;
      flex-grow: 1;
      &-label {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 40px;
        background: var(--pure-white);
        border: 1px solid var(--divider);
        border-radius: 16px;
        flex-grow: 1;
        aspect-ratio: 2/1;
        .sender-info {
          position: absolute;
          top: 40px;
          left: 40px;
        }
        .shipping-info {
          position: absolute;
          right: 40px;
          top: 40px;
        }
        @media (max-width: "640px") {
          aspect-ratio: 3/4;
        }
      }
    }
  }
}

.copywrite {
  padding: 24px;
  text-align: center;
  background-color: var(--pure-white);
  border-top: 1px solid var(--divider);
}

.double-inputs {
  display: flex;
  gap: 8px;
}

.tripple-inputs {
  display: flex;
  gap: 8px;
  .input {
    &:first-of-type {
      flex: 1 0 60%;
    }
  }
}

.custom-selector {
  position: relative;
  flex-grow: 1;
}

.selector-button {
  cursor: pointer;
  border: 2px solid #e6e6e6;
  border-radius: 4px;
  caret-color: #4aa199;
  padding: 12px 16px;
  transition: all 0.2s ease-in-out;
  width: 100%;
}

.options-list {
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  right: 0;
  background-color: var(--pure-white);
  list-style: none;
  margin: 0;
  padding: 0;
  z-index: 1000;
  color: var(--text);
  text-align: center;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: var(--soft-shadow);
}

.option-item {
  padding: 10px;
  cursor: pointer;
}

.option-item:hover,
.option-item.selected {
  background-color: var(--accent-active);
  color: var(--pure-white);
}
