*::before,
*::after {
  box-sizing: border-box;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  transition: all 0.35s ease-out;
}

html {
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}

img,
picture,
video,
canvas,
svg {
  vertical-align: top;
  max-width: 100%;
  height: auto;
}

input,
button,
textarea,
select {
  font-family: inherit;
  font-size: inherit;
}

button {
  cursor: pointer;
}

a {
  display: inline-block;
  text-decoration: none;
  font-family: var(--font-body);
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

label {
  display: inline-block;
  font-family: var(--font-body);
}

@media (hover: none) {
  .cta:focus,
  .cta:hover {
    background-color: inherit;
  }
}
