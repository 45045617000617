#skulabs {
  overflow-x: clip;
  @media (max-width: 768px) {
    padding-top: 74px;
    header {
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 100;
      border-bottom: 2px solid var(--accent);
    }
  }
}

body {
  background-color: var(--main-background, #f6f9f8);
  max-width: 100%;
  min-height: 100dvh;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
}

.video-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.3;
}

.custom-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 16px 0 24px;
  li {
    display: flex;
    gap: 8px;
    &::before {
      content: "";
      display: block;
      min-width: 24px;
      height: 24px;
      background: url("../../public/icons/asterics-accent.svg") center center
        no-repeat var(--pure-white);
      border-radius: 16px;
    }
  }
  &.other {
    li::before {
      background: url("../../public/icons/asterics-white.svg") center center
        no-repeat var(--accent);
    }
  }
}
