.video-wrapper {
  position: relative;
  background-color: #fff;
  border: 1px solid #7c7c7c;
  border-radius: 24px;
  max-width: 1360px;
  padding: 16px;
  box-shadow: 0px 40px 56px 0px rgba(6, 25, 23, 0.1);
  overflow: hidden;

  .player-screen-size {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 24px;
    @media (max-width: 768px) {
      padding: 8px;
    }
  }

  .preview {
    cursor: pointer;
    border: 1px solid #afaeae;
    border-radius: 8px;
    max-width: 100%;
    object-fit: cover;
    width: 100%;
  }

  .play-button {
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    gap: 12px;
    align-items: center;
    background: var(--accent);
    border-radius: 44px;
    max-height: 88px;
    transition: all 0.5s;
    translate: -50% -50%;
    padding: 16px 24px;
    min-width: 230px;
    animation: videoBtnAnimation 1.5s linear infinite;
    &::after {
      content: "Watch Video";
      display: block;
      font-family: var(--font-body);
      color: var(--pure-white, #fff);
      font-size: 24px;
      line-height: 44px;
      overflow: hidden;
      transition: all 0.5s;
      white-space: nowrap;
    }

    @media (max-width: 768px) {
      transform: scale(0.8);
    }
  }
  @media (max-width: 768px) {
    padding: 8px;
    border-radius: 16px;
    .play-button {
      scale: 0.8;
    }
  }
}

@keyframes videoBtnAnimation {
  0% {
    outline: 0px solid rgba(#36827b, 0.5);
  }
  50% {
    outline: 24px solid rgba(#36827b, 0.5);
  }
  80% {
    outline: 32px solid rgba(#36827b, 0);
  }
  100% {
    outline: 32px solid rgba(#36827b, 0);
  }
}
