:root {
  --font-title: "Roboto Slab", Georgia, serif;
  --font-body: "Readex Pro", Arial, sans-serif;
  --color-title: #061b23;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4 {
  text-wrap: balance;
}

h1 {
  color: var(--color-title);
  font-size: 56px;
  line-height: 64px;
  font-family: var(--font-title);
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.08rem;
  margin-bottom: 32px;
  line-height: 4.5rem;
}

h2 {
  color: var(--color-title);
  font-family: var(--font-title);
  font-size: 3.5rem;
  line-height: 4rem;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.035rem;
  margin-bottom: 1.5rem;
}

h3 {
  font-family: var(--font-title);
  font-size: 36px;
  font-style: normal;
  line-height: 44px;
  margin-bottom: 20px;
}

h4 {
  color: var(--title, #061b23);
  font-family: var(--font-title);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 12px;
}

p,
address {
  font-family: var(--font-body);
  font-style: normal;
  font-size: 18px;
  font-weight: 300;
  line-height: 28px;
}

ul,
ol {
  font-family: var(--font-body);
  list-style: none;
  line-height: 28px;
}

.title {
  color: var(--color-title);
  font-family: var(--font-title);
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 8px;
}

.small-title {
  display: inline-block;
  color: var(--color-title);
  font-family: var(--font-title);
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 16px;
}

@media (max-width: 960px) {
  h1 {
    font-size: 2.5rem;
    line-height: 3rem;
    letter-spacing: 0;
  }

  h2 {
    font-size: 2.25rem;
    line-height: 2.5rem;
    letter-spacing: 0;
  }

  p {
    font-size: 0.875rem;
    line-height: 1.5rem;
  }
}

@media (max-width: 640px) {
  h1 {
    font-size: 1.75rem;
    line-height: 2.25rem;
    margin-bottom: 16px;
  }
  h2 {
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 1rem;
  }
  h3 {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
}
