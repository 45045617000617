.check-email-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  .cta {
    min-width: 240px;
  }
}

.choose-plan {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 640px;
  h1 {
    text-align: left;
    @extend h3;
  }
}

.signup {
  display: flex;
  gap: 24px;
  justify-content: space-between;
  align-items: start;
  padding: 40px;
  background-color: var(--pure-white);
  border-radius: 24px;
  border: 1px solid var(--divider);
  .choose-plan {
    position: sticky;
    top: 20px;
    padding: 40px;
    border: 2px solid var(--divider);
    border-radius: 16px;
    background: #f9f9f9;
  }
  .plan {
    z-index: 0;
    box-shadow: none;
    transform: scale(1);
    &-value {
      gap: 8px;
      &:before {
        content: attr(data-rowname) ":";
        color: var(--title);
        font-weight: bold;
        opacity: 0.8;
      }
    }
    .cta:not(.cta-secondary) {
      display: none;
    }
    &::after {
      content: "30 day money back guarantee" !important;
      top: -20px !important;
    }
    &-info {
      min-height: inherit;
    }
    @media (max-width: 1280px) {
      min-width: 380px;
    }
  }

  .select {
    &-box {
      flex-wrap: wrap;
      font-weight: bold;
      font-size: 20px;
      color: var(--accent);
      &::after {
        content: "SKULabs PRO ";
        color: var(--text);
        width: 100%;
        order: -1;
      }
    }
  }
  @media (max-width: 1024px) {
    padding: 40px 20px;
  }

  @media (max-width: 980px) {
    flex-direction: column;
    .plan,
    .choose-plan {
      position: relative;
      top: 0;
      max-width: 100%;
      width: 100%;
      order: 0;
    }
  }
  @media (max-width: 640px) {
    padding: 0 10px;
    border: none;
  }
}
