.team {
  margin-bottom: var(--margin-section);
  padding-bottom: var(--margin-section);
  background-color: var(--pure-white);
  h2 {
    margin-bottom: 40px;
    @media (max-width: 640px) {
      text-align: center;
    }
  }
}

.about-us-video {
  padding: 20px;
  background-color: #fff;
  border-top: 1px solid var(--accent);
  margin-top: 320px;
  margin-bottom: -240px;
  .video-wrapper {
    margin: auto;
    transform: translateY(-300px);
  }
  @media (max-width: 767px) {
    margin-top: 200px;
    margin-bottom: 0;
    .video-wrapper {
      transform: translateY(-180px);
      margin-bottom: -160px;
    }
  }
}

// .teammembers {
//   display: flex;
//   flex-wrap: wrap;
//   gap: 48px;

//   .teammember {
//     &-card {
//       position: relative;
//       display: flex;
//       gap: 16px;
//       align-items: center;
//       flex: 0 0 calc((100% - 96px) / 3);
//       min-width: 260px;
//       text-align: center;
//     }

//     &-avatar {
//       max-width: 420px;
//       height: 480px;
//       object-fit: cover;
//       border-radius: 24px;
//     }
//     &-info {
//       position: absolute;
//       bottom: 40px;
//       left: 40px;
//       right: 40px;
//       display: flex;
//       flex-direction: column;
//       padding: 12px 24px;
//       background-color: rgba(255, 255, 255, 0.8);
//       border-radius: 8px;
//       backdrop-filter: blur(4px);
//       p {
//         margin-bottom: 0;
//       }
//     }
//     &-name {
//       font-weight: bold;
//       font-size: 20px;
//     }
//   }

//   @media (max-width: "768px") {
//   }
// }

.join-team {
  padding: 0 20px;
  p {
    margin-bottom: 20px;
  }
}

.whr {
  &-items {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 40px;
  }
  &-item {
    position: relative;
    background-color: var(--pure-white);
    border: 1px solid var(--divider);
    padding: 32px;
    border-radius: 24px;
  }
  &-title {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 8px;
    a {
      color: var(--title);
      font-weight: bold;
      &:hover {
        color: var(--accent);
        text-decoration: underline;
      }
    }
  }
}

.teammembers {
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  .teammember {
    &-card {
      display: flex;
      gap: 16px;
      align-items: center;
      flex: 0 0 30%;
      @media (max-width: 980px) {
        flex: 1 0 45%;
      }
      @media (max-width: 980px) {
        flex: 1 0 45%;
      }
      @media (max-width: 640px) {
        flex-direction: column;
        text-align: center;
      }
    }
    &-avatar {
      border-radius: 100%;
      width: 80px;
      height: 80px;
      object-fit: cover;
    }
    &-name {
      font-size: 20px;
      font-weight: bold;
    }
    &-position {
      color: var(--accent);
    }
  }
}
