.cta,
.cta-secondary,
.cta-border {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border-radius: 4px;
  font-size: 18px;
  font-weight: bold;
  gap: 12px;
  padding: 16px 24px;
}

.cta {
  font-family: var(--font-body);
  background: var(--accent, #36827b);
  color: #fff;
  letter-spacing: 0.32px;
  border: none;
  .icon {
    min-width: 24px;
  }
  &.small {
    padding: 8px 16px;
    gap: 8px;
  }
  &.big {
    padding: 32px 48px;
    font-size: 18px;
    border-radius: 16px;
  }
  &:hover {
    background: var(--accent-hover);
    box-shadow: 0px 0px 48px rgba(35, 130, 120, 0.36);
  }
  &-white {
    background-color: var(--pure-white);
    color: var(--accent);
    &:hover {
      background: rgba(255, 255, 255, 0.95);
    }
  }
  &:disabled {
    opacity: 0.3;
    pointer-events: none;
  }
}

.cta-secondary {
  background: #fff;
  color: #020908;
  .icon {
    min-width: 24px;
  }
  &.small {
    padding: 12px 16px;
  }
  &:hover {
    box-shadow: 0px 0px 24px rgba(35, 130, 120, 0.5);
  }
}

.cta-border {
  font-family: var(--font-body);
  font-weight: bold;
  color: var(--dark-background, #061917);
  border: 2px solid var(--divider, #d8e0df);
  .icon {
    min-width: 24px;
  }
  &.small {
    padding: 12px 16px;
  }
  &:hover {
    border-color: var(--accent);
    color: var(--accent-active);
  }
}

.ctas {
  display: flex;
  gap: 16px;
  align-items: center;
  @media (max-width: 560px) {
    flex-direction: column;
    width: 100%;
    & > * {
      width: 100%;
    }
  }
}

.cta-live {
  padding: 16px 24px;
  color: var(--pure-white, #fff);
  font-family: var(--font-body);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  border: none;
  background-color: #36827b;
  align-items: center;
}

.cta-live:hover {
  background-color: #3f8b84;
}

.cta-live:active {
  background-color: #125e57;
}

.link-more,
.cta-link {
  font-family: var(--font-body);
  font-weight: bold;
  font-size: 18px;
  color: var(--accent, #36827b);
  line-height: 24px;
  &:hover {
    color: var(--accent-hover);
    border-color: 1px solid var(--accent-hover);
    text-decoration: underline;
    .arrow {
      rotate: 45deg;
    }
  }
}

.call-link {
  color: var(--title);
  font-size: 18px;
  font-weight: bold;
  transition: all 0.35s;
  &:hover {
    text-decoration: underline;
    color: var(--accent-hover);
  }
  span {
    text-wrap: nowrap;
  }
}
