.integration {
  background: var(--pure-white);
  padding: var(--margin-header) 0;
  @media (max-width: 768px) {
    margin-top: 0;
  }
  &-container {
    align-items: flex-start;
    display: flex;
    gap: 24px;
    position: relative;
    width: 100%;
    margin-bottom: var(--margin-header);
    @media (max-width: 767px) {
      flex-direction: column-reverse;
    }
  }
  .cards-container {
    flex-grow: 1;
  }

  &-aside {
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
    @media (max-width: 768px) {
      top: 96px;
    }
  }

  &-filter {
    background: #f9f9f9;
    border: 1px solid var(--divider, #d8e0df);
    border-radius: var(--gap-xs, 12px);
    display: flex;
    flex: 0 0 320px;
    flex-direction: column;
    gap: 16px;
    padding: 32px 24px;
    &-list {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
    .title {
      font-weight: bold;
      margin-bottom: 0;
    }
    @media (max-width: 960px) {
      display: none;
    }
  }
  &-cards-list {
    align-items: stretch;
    align-self: stretch;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    flex-grow: 1;
  }
  &-card {
    display: flex;
    flex-direction: column;
    gap: 24px;
    flex: 1 0 calc(33.33333% - 8px);
    border: 1px solid var(--divider, #d8e0df);
    border-radius: 12px;
    justify-content: space-between;
    padding: 32px 24px;
    min-width: 280px;
    &-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
    }
    &:hover {
      box-shadow: var(--soft-shadow);
    }
    &:last-of-type {
      background: var(--light-background, #eef5f4);
    }
  }
  &-logo {
    height: 36px;
    max-width: 100%;
  }

  &-features-short {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-left: 12px;
    list-style: disc;
    color: var(--text);
  }
  &-link {
    color: var(--accent);
    &:hover {
      text-decoration: underline;
    }
  }

  .tags-list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    .tag {
      background: #f9f9f9;
      border: 1px solid var(--divider, #d8e0df);
      border-radius: 4px;
      color: var(--text, #333938);
      font-family: var(--font-body);
      font-size: 12px;
      letter-spacing: 0.03rem;
      line-height: 1;
      padding: 4px 8px;
    }
  }

  .filters {
    background: var(--main-background, #f6f9f8);
    border-radius: var(--gap-xs, 8px);
    display: flex;
    gap: 12px;
    left: 0;
    margin-bottom: 24px;
    padding: 12px;
    position: sticky;
    top: 0;
    @media (max-width: 768px) {
      top: 96px;
    }
    @media (max-width: 768px) {
      top: 96px;
    }
    @media (max-width: 767px) {
      top: 74px;
      margin: 0 -20px;
    }
    input {
      width: 100%;
    }
    .filter-button {
      align-items: center;
      background: #fff;
      background: var(--pure-white, #fff);
      border: 1px solid #848484;
      border: 1px solid var(--grayscale, #848484);
      border-radius: 4px;
      display: none;
      gap: 8px;
      min-height: 48px;
      padding: 0 12px;
      min-width: 96px;
      @media (max-width: 960px) {
        display: flex;
      }
    }
    .filter-container.mobile {
      display: none;
      @media (max-width: 1023px) {
        &.show {
          display: flex;
          flex-direction: column;
          left: 0;
          position: absolute;
          top: 80px;
          width: 100%;
          background: #f9f9f9;
          border: 1px solid var(--divider, #d8e0df);
          border-radius: 4px;
          padding: 24px;
          .integration-filter-list {
            align-self: stretch;
            flex-direction: row;
            justify-content: flex-start;
            flex-wrap: wrap;
            gap: 24px 48px;
          }
        }
      }
    }
  }
}

.more-information {
  margin-top: 4rem;
  p:not(:last-of-type) {
    margin-bottom: 20px;
    font-size: 18px;
  }
}

.aside-cta {
  padding: 32px 24px;
  border-radius: var(--gap-xs, 8px);
  min-width: 320px;
  background: linear-gradient(
    252deg,
    #edfbde 0%,
    #e3f9e3 14%,
    #d6f5e7 28%,
    #d0f2e8 42%,
    #cceee9 56%,
    #ccebe9 70%,
    #cce7e9 84.77%,
    #cce3e7 100%
  );
  .ctas {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}
