.case-study {
  margin-top: var(--margin-header);
  margin-bottom: var(--margin-section);
  .video-wrapper {
    transform: inherit;
    margin: 0 auto;
  }
  &-content {
    background-color: var(--pure-white);
    margin-top: -480px;
    padding: 560px 0 var(--margin-section);
    border-top: 1px solid var(--divider);
    .container {
      display: flex;
      gap: 80px;
    }
    .content {
      flex: 1;
      h2:not(:first-of-type),
      h3 {
        margin-top: 24px;
      }
      p:not(:last-of-type) {
        margin-bottom: 16px;
      }
      ul > li {
        border-left: 3px solid var(--accent);
        padding: 2px 12px;
        margin-bottom: 12px;
      }
      img {
        margin: 24px 0 8px;
        border-radius: 24px;
      }
    }
  }

  aside {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 440px;
    .highlights {
      padding: 32px;
      border-radius: 24px;
      border: 2px solid var(--divider);
      ul li {
        list-style: disc;
        margin: 12px 20px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  .results {
    position: sticky;
    top: 20px;
    border-radius: 24px;
    background: radial-gradient(
        80% 80% at 50% 100%,
        rgba(41, 89, 55, 1) 0%,
        rgba(41, 89, 55, 0.1) 100%
      )
      var(--dark-background, #061917);
    color: var(--pure-white);
    padding: 40px;

    .result {
      display: flex;
      gap: 16px;
      align-items: center;
      padding-bottom: 16px;
      margin-bottom: 16px;
      border-bottom: 2px solid;
      border-image: linear-gradient(
          to right,
          rgba(255, 255, 255, 0.02) 0%,
          rgba(255, 255, 255, 0.3) 50%,
          rgba(255, 255, 255, 0.02) 100%
        )
        1;
      &-number {
        flex: 0 0 140px;
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        line-height: 64px;
      }
      &-description {
        flex: 1 0 220px;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
      &:last-of-type {
        border: none;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }

  &-hero {
    display: flex;
    justify-content: center;
    gap: 40px;
    margin-bottom: var(--margin-header);
    h1 {
      text-align: left;
    }
    &-info {
      max-width: 680px;
    }
    &-img {
      flex: 0 0 calc(100% - 720px);
      max-width: calc(100% - 720px);
      max-height: 480px;
      min-width: 320px;
      img {
        max-width: initial;
        border-radius: 40px;
        height: 100%;
      }
    }
  }

  @media (max-width: 1279px) {
    .video-wrapper {
      margin: 0 20px;
    }
    &-hero {
      &-img {
        display: none;
      }
    }
    &-content {
      .container {
        gap: 20px;
      }
    }
  }

  @media (max-width: 980px) {
    &-content {
      .container {
        flex-direction: column;
      }
      aside {
        order: -1;
      }

      aside {
        max-width: 100%;
      }

      .results {
        display: flex;
        flex-wrap: wrap;
        padding: 20px;
        h3 {
          flex: 1 0 100%;
          text-align: center;
          margin-bottom: 20px;
        }
        .result {
          flex: 1;
          gap: 4px;
          flex-direction: column;
          border-bottom: none;
          border-right: 2px solid;
          border-image: linear-gradient(
              to bottom,
              rgba(255, 255, 255, 0.02) 0%,
              rgba(255, 255, 255, 0.3) 50%,
              rgba(255, 255, 255, 0.02) 100%
            )
            1;
          text-align: center;
          margin-bottom: 0;
        }
        .result-number {
          font-size: 24px;
          line-height: 32px;
          flex: 0 0 auto;
        }
        .result-description {
          font-size: 14px;
          line-height: 20px;
          flex: 0 0 auto;
        }
      }
    }
  }

  @media (max-width: 640px) {
    &-content {
      margin-top: -80px;
      padding: 120px 0 var(--margin-section);
    }
    .results {
      flex-direction: column;
      gap: 16px;
      h3 {
        margin-bottom: 0;
      }
      .result {
        border-right: none;
        border-bottom: 2px solid;
        border-image: linear-gradient(
            to right,
            rgba(255, 255, 255, 0.02) 0%,
            rgba(255, 255, 255, 0.3) 50%,
            rgba(255, 255, 255, 0.02) 100%
          )
          1;
      }
    }
  }
}
