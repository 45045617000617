.contact {
  &-page {
    h1 {
      text-align: left;
    }
    &-info {
      max-width: 640px;
    }
    display: flex;
    gap: 40px;
  }
  &-links {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    margin-top: 32px;
  }
  &-link {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-radius: var(--gap-s, 16px);
    border: 1px solid var(--divider, #d8e0df);
    background: #fff;
    color: var(--accent, #36827b);
    font-family: var(--font-title);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    min-width: 280px;
    &:hover {
      box-shadow: var(--soft-shadow);
    }
    &-title {
      font-size: 18px;
      color: var(--title, #061b23);
    }
  }
}
