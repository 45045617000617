.testimonial {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 64px;
  margin-bottom: var(--margin-section);
  &-companies {
    display: flex;
    gap: 40px;
  }
  &-company {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    background-color: var(--pure-white);
    border: 1px solid var(--divider);
    border-radius: 24px;
    padding: 40px 24px;
    &:hover {
      box-shadow: 0px 15px 30px 0px rgba(71, 103, 136, 0.06),
        0px 8px 15px 0px rgba(71, 103, 136, 0.03),
        0px 4px 4px 0px rgba(71, 103, 136, 0.04);
    }
  }
  &-annotation {
    color: var(--accent, #36827b);
  }

  &-item {
    display: flex;
    gap: 40px;
  }
  &-rating {
    display: flex;
    gap: 4px;
  }

  @media (max-width: 960px) {
    margin-top: 32px;
    gap: 16px;
    &-companies {
      gap: 16px;
      flex-wrap: wrap;
    }
    &-company:first-of-type {
      flex: 1 0 100%;
    }
  }
  &-slider {
    min-height: 560px;
  }
}

.reviewer {
  &-container {
    position: relative;
    flex: 1 0 calc(55% - 20px);
    border-radius: 24px;
    border: 1px solid var(--divider, #d8e0df);
    background: var(--pure-white, #fff);
    padding: 80px 56px;
    min-height: 604px;
  }
  &-name {
    margin-bottom: 0;
  }
  &-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 48px;
    height: 100%;
  }
  &-description {
    color: var(--text, #333938);
    text-align: center;
    font-family: var(--font-title);
    font-size: 20px;
    line-height: 32px;
    max-width: 90%;
  }
  &-info {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
    &.center {
      justify-content: center;
    }
  }
  &-avatar {
    display: block;
    flex: 0 0 calc(45% - 20px);
    min-height: 520px;
    object-fit: cover;
    border-radius: 24px;
    background: var(--pure-white);
    overflow: hidden;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  &-position {
    color: var(--accent, #36827b);
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 4px;
  }

  @media (max-width: 1024px) {
    &-avatar {
      display: none;
    }
  }
  @media (max-width: 640px) {
    &-container {
      padding: 40px;
      min-height: 540px;
    }
    &-content {
      justify-content: center;
    }
    &-description {
      font-size: 16px;
      line-height: 28px;
    }
    &-info {
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 12px;
    }
  }
}

.slider-controls {
  position: absolute;
  top: 50%;
  left: 16px;
  display: flex;
  justify-content: space-between;
  width: calc(100% - 32px);
  transform: translateY(-50%);
  .arrow {
    cursor: pointer;
    &:hover {
      background-color: var(--divider);
      border-radius: 100%;
    }
    &.prev {
      scale: -1;
    }
  }
}

.testimonial-item.animate {
  .reviewer {
    &-avatar img,
    &-content {
      opacity: 0;
      scale: 0.3;
    }
  }
}

.stars-container {
  display: flex;
  gap: 8px;
  font-size: 24px;
  font-weight: bold;
  color: var(--title);
}
