.industries {
  &-hero {
    margin-top: -64px;
    padding-top: 80px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    overflow-x: hidden;
    .container {
      display: flex;
      gap: 20px;
      align-items: flex-end;
      justify-content: space-between;
    }
  }
  &-info {
    min-width: 560px;
    max-width: 1000px;
    padding-bottom: 60px;
    &.center {
      text-align: center;
      margin: 0 auto;
      h1 {
        text-align: center;
      }
      .email-form {
        margin: 40px auto;
      }
    }
    h1 {
      text-align: left;
    }
    .email-form {
      margin: 40px 0;
    }
  }
  &-metrics {
    background: radial-gradient(
        60% 90% at 50% 100%,
        rgb(37, 68, 50) 0%,
        rgba(37, 68, 50, 0.1) 100%
      )
      #061917;
    padding: 48px 0;
    .container {
      display: flex;
      gap: 24px;
      justify-content: space-around;
      color: var(--pure-white);
    }
  }
  &-errors {
    padding: var(--margin-section) 0;
    .container {
      display: flex;
      gap: 80px;
    }

    &-info {
      max-width: 640px;
    }
    &-list {
      display: flex;
      flex-direction: column;
      gap: 12px;
      min-width: 400px;
      @media (max-width: 640px) {
        min-width: initial;
      }
    }
    &-item {
      display: flex;
      padding: var(--gap-s, 16px) 24px;
      align-items: flex-start;
      gap: 12px;
      align-self: stretch;
      border-radius: 12px;
      border: 1px solid #ffd8d1;
      background: #fdfbfa;
    }
    &-img {
      border-radius: 16px;
      margin-top: 32px;
    }
    @media (max-width: 1280px) {
      .container {
        gap: 24px;
      }
    }
  }
  &-benefits {
    position: relative;
    background-color: var(--pure-white);
    padding-bottom: var(--margin-section);
    border-top: 1px solid var(--divider);
    .solve-problems {
      display: block;
      position: absolute;
      top: -32px;
      left: 50%;
      transform: translateX(-50%);
      border-top: 1px solid var(--divider);
      color: var(--accent, #36827b);
      font-family: var(--font-body);
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      border-radius: var(--gap-xs, 8px);
      border: 1px solid var(--divider, #d8e0df);
      background: var(--pure-white, #fff);
      padding: 20px 40px;
      .arrow-target {
        position: absolute;
        top: calc(100% - 20px);
        left: calc(100% - 140px);
        transform: scaleX(-1);
      }
    }
    &-info {
      max-width: 960px;
      margin-bottom: 40px;
    }
    &-list {
      display: flex;
      flex-direction: column;
      gap: var(--margin-section);
    }
    &-group {
      display: flex;
      align-items: center;
      gap: 80px;
      &:nth-child(even) {
        flex-direction: row-reverse;
      }
      .cta {
        align-self: flex-start;
      }
      @media (max-width: 1280px) {
        gap: 24px;
      }
    }
    &-annotation {
      display: flex;
      flex-direction: column;
      gap: 24px;
      justify-content: space-between;
      min-width: 400px;
      max-width: 600px;
      @media (max-width: 640px) {
        min-width: 100%;
      }
    }
    &-img {
      position: relative;
      text-align: center;
      min-width: 520px;
      padding: 24px;
      border-radius: 24px;
      border: 1px solid var(--divider, #d8e0df);
      background: var(--main-background, #f6f9f8);
      box-shadow: 0px 0px 80px 12px rgba(35, 130, 120, 0.12);
      @media (max-width: 1280px) {
        min-height: 420px;
      }
      @media (max-width: 640px) {
        min-height: initial;
        min-width: 100%;
      }
    }
  }
  @media (max-width: 1200px) {
    &-hero {
      img {
        display: none;
      }
    }
    &-info {
      max-width: inherit;
      min-width: 100%;
      flex: 1;
    }
    &-errors {
      .container {
        flex-direction: column;
      }
      &-info {
        max-width: inherit;
        display: flex;
        flex-direction: column;
      }
      &-img {
        margin-top: 0;
        margin-bottom: 32px;
        width: 100%;
        order: -1;
      }
    }
    &-benefits {
      .solve-problems {
        white-space: nowrap;
        font-size: 16px;
      }
      &-group {
        flex-direction: column;
        &:nth-child(even) {
          flex-direction: column;
        }
      }
      &-annotation {
        max-width: inherit;
      }
    }
  }
}
