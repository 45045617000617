.custom-select-container {
  label {
    display: inline-block;
    margin-bottom: 8px;
  }

  .arrow {
    width: 12px;
    height: 12px;
    border-bottom: 2px solid var(--title);
    border-left: 2px solid var(--title);
    transform: rotate(-45deg);
  }
}

.custom-select {
  position: relative;
  width: 100%;
  cursor: pointer;
  color: var(--text);
  font-family: var(--font-body);
}

.select-box {
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border: 2px solid #7c7c7c;
  border-radius: 4px;
  background-color: #fff;
}

.options {
  position: absolute;
  top: calc(100% + 2px);
  width: 100%;
  border: 2px solid #7c7c7c;
  border-radius: 4px;
  background-color: var(--pure-white);
  box-shadow: var(--soft-shadow);
  z-index: 1000;
  .selected {
    background-color: var(--accent);
    color: var(--pure-white);
  }
}

.options div {
  padding: 16px;
  border-bottom: 2px solid #7c7c7c;
  font-weight: bold;
  font-size: 16px;
}

.options div:hover {
  background-color: var(--light-bg);
}

.plan-selector {
  cursor: pointer;
  position: relative;
  font-family: var(--font-body);
  &-input {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    padding: 16px;
    border-radius: 4px;
    border: 2px solid #7c7c7c;
  }
  &-options {
    position: absolute;
    top: calc(100% + 4px);
    margin-top: 8px;
    max-height: 0;
    overflow: hidden;
    border-radius: 4px;
    width: 100%;
    background-color: var(--pure-white);
    transition: 0.35s max-height;
    &.open {
      max-height: 200px;
      border: 2px solid #7c7c7c;
      z-index: 2;
      box-shadow: var(--soft-shadow);
    }
  }
  &-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    padding: 16px;
    &.selected {
      background-color: var(--accent);
      color: var(--pure-white);
    }
    .price {
      font-weight: bold;
      font-size: 20px;
    }
  }
}
