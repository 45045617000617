header {
  position: relative;
  background: var(--pure-white, #fff);
  border-bottom: 1px solid var(--divider);
  padding: 12px 0;

  .container {
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: space-between;
  }

  .login-signup {
    display: flex;
    gap: 32px;
    align-items: center;
    a {
      font-family: var(--font-body);
      text-decoration: none;
    }
  }

  .logo {
    min-width: 157px;

    @media (max-width: 1200px) {
      position: relative;
      z-index: 11;
    }
  }

  @media (max-width: 1200px) {
    & > .container .cta-link {
      display: none;
    }
  }

  @media (max-width: 640px) {
    & > .container .ctas {
      display: none;
    }
  }

  .burger {
    cursor: pointer;
    background: var(--pure-white);
    display: none;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
    width: 48px;
    height: 48px;
    padding: 0 6px;
    border: 2px solid var(--divider);
    border-radius: 4px;
    overflow: hidden;
    order: 1;
    span {
      display: block;
      height: 2px;
      width: 100%;
      background: var(--title);
      transition: transform 0.3s ease;
    }

    &.open {
      background: var(--divider);
      z-index: 10;

      span {
        background: var(--accent-active);

        &:nth-child(1) {
          transform: rotate(45deg) translate(5px, 4px);
        }

        &:nth-child(2) {
          transform: translateX(-40px);
          opacity: 0;
        }

        &:nth-child(3) {
          transform: rotate(-45deg) translate(5px, -4px);
        }
      }
    }

    @media (max-width: 1200px) {
      display: flex;
    }
  }
}

.desktop-menu {
  > ul {
    display: flex;
    gap: 40px;

    a:not(.small-title),
    span {
      display: block;
      font-family: var(--font-body);
      font-size: 18px;
      line-height: 24px;
      color: var(--text);
      text-decoration: none;
      &:hover {
        color: var(--accent);
      }
    }

    .small-title {
      text-decoration: none;

      &:hover {
        color: var(--accent);
      }
    }

    .menu-item {
      position: relative;
      display: flex;
      align-items: center;
      gap: 12px;

      a.active {
        color: var(--accent);
      }

      &:hover {
        .submenu {
          top: 100%;
          opacity: 1;
          z-index: 11;
        }

        .submenu-icon::before {
          transform: rotate(225deg) translate(-2px, -2px);
          border-color: var(--accent);
        }
      }
    }

    .submenu {
      position: absolute;
      top: calc(100% + 68px);
      left: 0;
      display: flex;
      justify-content: center;
      gap: 0 24px;
      opacity: 0;
      padding-top: 26px;
      background: none;
      z-index: -1;
      border-radius: 0 0 8px 8px;
      overflow: hidden;
      a {
        padding: 12px 16px;
        min-width: 160px;
        text-wrap: nowrap;
        background: var(--pure-white);
        border: 1px solid var(--divider);
        &:hover {
          background: var(--divider);
        }
      }
      .extra-submenu {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      &:not(.has-extra-submenu) {
        flex-direction: column;
      }
    }
  }
}

.mobile-menu {
  position: absolute;
  top: 72px;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: 100%;
  height: calc(100dvh - 72px);
  overflow-y: auto;
  border-top: 1px solid var(--divider);
  background-color: var(--pure-white, #fff);
  padding-bottom: 40px;
  z-index: 10;

  ul {
    width: 100%;
  }

  .menu-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--pure-white);
    border-bottom: 1px solid var(--divider);

    a:not(.small-title),
    span {
      font-family: var(--font-body);
      font-size: 16px;
      line-height: 24px;
      color: var(--text);
      text-decoration: none;
      opacity: 0.9;

      &:hover {
        color: var(--accent);
      }
    }

    .menu-link {
      cursor: pointer;
      flex-grow: 1;
      padding: 12px;
      &:hover {
        background: var(--divider);
      }
    }
  }

  .submenu,
  .extra-submenu {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease, opacity 0.3s ease;
    opacity: 0;
  }

  .submenu {
    .menu-link {
      padding-left: 20px;
    }
  }

  .menu-item.open {
    > .submenu,
    > .extra-submenu {
      max-height: 1000px;
      opacity: 1;
    }

    > .menu-title > .menu-link {
      color: var(--accent);
    }

    > .submenu {
      display: flex;
      flex-direction: column;
    }

    > .menu-title .submenu-icon::before {
      transform: rotate(225deg) translate(-1px, -1px);
      border-color: var(--accent);
    }
  }

  .extra-submenu .menu-title .menu-link {
    padding: 12px 24px;
  }

  .submenu-icon {
    cursor: pointer;
    min-width: 48px;
    height: 48px;
    border-left: 1px solid var(--divider);
    &:hover {
      background: var(--divider);
    }
  }
}

.submenu-icon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    content: "";
    display: block;
    width: 12px;
    height: 12px;
    border-bottom: 2px solid var(--title);
    border-right: 2px solid var(--title);
    border-bottom-right-radius: 2px;
    transform: rotate(45deg) translate(-3px, -3px);
    transition: all 0.35s ease-out;
  }
}

.social {
  display: flex;
  gap: 20px;
  align-items: center;
  @media (max-width: 480px) {
    flex-wrap: wrap;
    justify-content: center;
    .phone-number {
      flex: 1 0 100%;
      text-align: center;
    }
  }
  &-link:hover {
    path {
      fill: var(--accent);
    }
  }
}

.dark-theme {
  path {
    fill: var(--title);
  }
}
