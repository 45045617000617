.hero-integration {
  background: no-repeat 50% / cover;
  padding: 80px 40px;
  text-align: center;

  &.extra-bottom-padding {
    padding-bottom: 240px;
  }

  .integration-logos {
    align-items: center;
    display: flex;
    gap: 40px;
    justify-content: center;
    position: relative;
    z-index: 2;
  }

  h1 {
    margin: 0 auto 24px;
    max-width: 1092px;
  }
  .description {
    margin: 24px auto;
    max-width: 900px;
  }
  .plus {
    position: relative;
    width: 32px;
    height: 32px;
    &:after,
    &:before {
      background: #061b23;
      border-radius: 2px;
      content: "";
      position: absolute;
    }
    &:before {
      height: 32px;
      left: 14px;
      top: 0;
      width: 4px;
    }
    &:after {
      height: 4px;
      left: 0;
      top: 14px;
      width: 32px;
    }
  }
  .integration-company {
    position: relative;
    .flow {
      position: absolute;
      top: calc(100% - 12px);
      z-index: 2;
      &-left {
        right: -12px;
      }

      &-right {
        left: -12px;
      }
      @media (max-width: 768px) {
        transform-origin: top;
        transform: scale(0.8);
      }
    }
  }
}

.single-integration {
  background: var(--pure-white);
  padding-bottom: var(--margin-section);
  margin-bottom: var(--margin-section);
  h2 {
    margin-bottom: 40px;
    text-align: center;
  }

  .feature-item {
    display: flex;
    gap: 40px;
    padding: 24px;
    border-bottom: 2px solid var(--divider);
    &:first-of-type {
      border-top: 2px solid var(--divider);
    }
    .title {
      font-weight: bold;
      min-width: 400px;
      @media (max-width: 640px) {
        min-width: inherit;
      }
    }
    img {
      margin-bottom: 12px;
    }
    @media (max-width: 960px) {
      flex-direction: column;
      gap: 0;
    }
  }
  @media (max-width: 1024px) {
    .video-wrapper {
      transform: translateY(-280px);
    }
  }
}

.made-simple {
  padding-top: var(--margin-section);
}

.target-integration {
  .container {
    display: flex;
    align-items: center;
    gap: 40px;
    margin: var(--margin-section) auto;
  }

  .integration-gif {
    flex: 1 0 auto;
  }
  .logos {
    display: flex;
    flex-wrap: wrap;
    gap: 40px 24px;
    justify-content: center;
    margin: 40px 0;
    .logo {
      flex: 1 0 18%;
      max-height: 40px;
      min-width: 120px;
    }
  }
  @media (max-width: 1280px) {
    .container {
      flex-direction: column;
    }
    .target-integration-info {
      max-width: 800px;
    }
  }
}

.capabilities {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  .capabilitie {
    display: flex;
    gap: 24px;
    align-items: start;
    padding: 24px;
    border-radius: 16px;
    border: 2px solid var(--divider);
    flex: 1 0 40%;
    &:hover {
      box-shadow: var(--soft-shadow);
    }
    img {
      margin-bottom: 12px;
    }
    .link-more {
      margin-top: 16px;
      img {
        margin-bottom: 0;
      }
    }
    .title {
      margin-bottom: 8px;
    }
    a:hover .title {
      color: var(--accent);
    }
  }

  @media (max-width: 960px) {
    gap: 12px;
  }
  @media (max-width: 640px) {
    flex-direction: column;
  }
}
