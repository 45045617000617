.aside {
  &-menu {
    position: sticky;
    top: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    min-width: 320px;
    max-width: 320px;
    padding: 32px;
    background-color: #f9f9f9;
    border: 1px solid var(--divider);
    list-style: none;
    border-radius: 16px;
    .title {
      margin-bottom: 0;
    }
    a {
      color: var(--title);
      font-size: 18px;
      &:hover {
        color: var(--accent);
        text-decoration: underline;
      }
      &.active {
        color: var(--accent);
      }
    }
    ul {
      display: flex;
      flex-direction: column;
      gap: 12px;
      font-family: var(--font-body);
    }
    &:hover {
      box-shadow: var(--soft-shadow);
    }
    @media (max-width: 767px) {
      max-width: initial;
      width: 100%;
      ul {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 24px;
        li {
          flex: 1 0 40%;
        }
      }
    }
  }
}
