$animation-duration: 50s;

.carousel {
  position: relative;
  margin: 40px 0;

  .carousel-track {
    display: flex;
    width: max-content;

    &.scroll-left {
      animation: scroll-left $animation-duration linear infinite;
    }

    &.scroll-right {
      animation: scroll-right $animation-duration linear infinite;
    }
    &:hover {
      animation-play-state: paused;
    }
  }

  .carousel-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    width: 240px;
    height: 120px;
    margin: 0 20px;
    border-radius: 16px;
    border: 1px solid #d8e0df;
    background: #fff;
    padding: 24px 32px;
    box-shadow: 0px 12px 24px 0px rgba(71, 103, 136, 0.06),
      0px 8px 15px 0px rgba(71, 103, 136, 0.03),
      0px 4px 4px 0px rgba(71, 103, 136, 0.04);

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      display: block;
    }
    &:hover {
      border-radius: 17.6px;
      border: 1.1px solid var(--accent, #36827b);
      background: var(--light-background, #eef5f4);
      box-shadow: 0px 0px 48px 0px rgba(35, 130, 120, 0.16);
      transform: scale(1.1);
    }
  }

  @media (max-width: 1024px) {
    .carousel-item {
      transform: scale(0.8);
      margin: -20px -10px;
    }
  }
}

@keyframes scroll-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

@keyframes scroll-right {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0);
  }
}
