.academy {
  display: flex;
  gap: 40px;
  align-items: stretch;
  padding: 0 20px;
  img {
    margin-bottom: 40px;
    width: 100%;
    border-radius: 16px;
  }
  .help-info {
    background-color: var(--info-bg);
    border: 1px solid var(--info);
    border-radius: 12px;
    padding: 24px;
    margin-top: 0;
    font-family: var(--font-body);
    font-weight: normal;
  }
  &-next {
    display: block;
    text-align: center;
    margin-top: 32px;
    padding: 24px;
    background-color: var(--light-bg);
    border: 1px solid var(--accent);
    border-radius: 12px;
  }

  &-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  &-item {
    flex: 1 0 30%;
    text-align: center;
    min-width: 320px;
    img {
      height: 240px;
      width: 100%;
      object-fit: cover;
      margin-bottom: 12px;
    }
  }

  @media (max-width: 960px) {
    padding: 0;
  }

  @media (max-width: 640px) {
    flex-direction: column;
    aside {
      order: 1;
    }
    h1 {
      margin-bottom: 16px;
    }
  }
}

.notFound {
  text-align: center !important;
}
