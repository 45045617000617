.hardware {
  .success {
    position: static;
    transform: inherit;
  }
  &-links {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
  }
  &-link {
    @extend .small-title;
    background-color: var(--pure-white);
    border: 1px solid var(--divider);
    border-radius: 4px;
    padding: 12px 24px;
    &:hover {
      box-shadow: var(--soft-shadow);
    }
    &.active {
      background-color: var(--accent);
      color: var(--pure-white);
    }
  }
  &-list {
    display: flex;
    gap: 40px;
    flex-wrap: wrap;
    min-width: 240px;
    margin-bottom: 40px;
  }
  &-card {
    flex: 1 0 30%;
    padding: 40px;
    min-width: 200px;
    background-color: var(--pure-white);
    border-radius: 24px;
    border: 1px solid var(--divider);
  }
  &-other-list {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    margin-left: 0 !important;
    li {
      padding: 8px 16px;
      background-color: var(--pure-white);
      border-radius: 20px;
      border: 1px solid var(--divider);
      list-style: none;
    }
  }
  @media (max-width: 960px) {
    &-list {
      gap: 16px;
    }
    &-card {
      padding: 24px;
      text-align: center;
    }
  }
  @media (max-width: 720px) {
    &-card {
      min-width: 340px;
    }
  }
}
